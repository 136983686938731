.features {
  padding-top: 75px;
  padding-bottom: 80px;
  background-color: #e7f5ff;
  margin-bottom: 50px;
  overflow: hidden;
}

.featuresTitle {
  margin: 0;
  margin-bottom: 54px;
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text);
  text-align: center;
}

.featuresCarousel {
  margin-inline: auto;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .featuresTitle {
    margin-bottom: 35px;
    font-size: 36px;
    line-height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .features {
    padding-top: 38px;
    padding-bottom: 40px;
    margin-bottom: 50px;
  }

  .featuresTitle {
    margin-bottom: 35px;
    font-size: 36px;
    line-height: 50px;
  }
}
