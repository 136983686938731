.form {
  max-width: 470px;
  width: 100%;
  border-radius: 70px;
  background: var(--white);
}

.inputContainer {
  padding: 45px 50px 50px;
}

.formHeader {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 32px;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: var(--primary-text);
  text-align: center;
}

.input {
  padding-left: 20px;
  position: relative;
  flex-grow: 1;
  min-height: 60px;
  border-radius: 5px;
  border: none;
  color: var(--second-text);
  background-color: #f1f9ff;
  font-size: 20px;
  font-weight: 500;
  line-height: 39px;
  margin-bottom: 0px;

  &::placeholder {
    color: #516f9a;
    font-size: 20px;
    font-weight: 500;
    line-height: 39px;
    opacity: 1;
  }

  &:target {
    color: var(--main-blue-hover);
    border-color: var(--main-blue-hover);
  }

  &:focus-visible {
    outline: none;
    color: var(--second-text);
    border-color: var(--main-blue-hover);
  }
}

.inputError.input:target {
  border-color: #ff6332;
}

.inputError.input:focus-visible {
  border-color: #ff6332;
}

.inputError.input {
  border-color: #ff6332;
}

.textarea {
  padding-top: 11px;
  min-height: 130px;
  resize: none;
  margin-bottom: 30px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 15px;
  padding: 22px 40px 23px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  transition: background-color 0.3s;
  min-height: 64px;
}

.hasSent.button {
  padding: 17px 38px;
}

.hasSent {
  background-color: #2fa41c !important;
}

.hasSentText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

@media screen and (max-width: 767px) {
  .form {
    border-radius: 50px;
  }

  .formHeader {
    padding-bottom: 32px;
    font-size: 30px;
    line-height: 37px;
    max-width: 14ch;
  }

  .formHeaderContainer {
    display: flex;
    justify-content: center;
  }

  .inputContainer {
    padding: 40px 30px 40px;
  }

  .button {
    padding: 17px 40px 18px;
    min-height: 60px;
  }
}
