img {
  max-width: 100%;
}

.platformInfo {
  position: relative;
  padding: 85px 80px 130px;
  max-width: 1470px;
  margin: 0 auto;
  text-align: center;
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.platformInfoHeader {
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text);
  text-align: center;
  max-width: 18ch;
}

.platformInfoSlider {
  padding-top: 8px;
  margin-inline: auto;
  width: 100%;
}

.button {
  padding: 23px 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  max-width: 200px;
  width: 100%;
  border-radius: 15px;
  transition: background-color 0.3s;
}

@media screen and (max-width: 1366px) {
  .platformInfo {
    padding: 10px 80px 70px;
  }
}

@media screen and (max-width: 1024px) {
  .platformInfoHeader {
    font-size: 36px;
    line-height: 50px;
    max-width: 14ch;
  }

  .container {
    margin-bottom: 70px;
    row-gap: 30px;
  }

  .stepNumber {
    font-size: 120px;
    line-height: 150px;
  }

  .stepTitle {
    font-size: 32px;
    line-height: 38px;
  }

  .stepDescription {
    font-size: 18px;
    line-height: 32px;
  }

  .stepImgBox1 {
    width: 100%;

    &::before {
      background-size: cover;
      width: 104px;
      height: 82px;
      top: 17%;
      right: 16%;
    }
  }

  .stepImgBox2 {
    &::before {
      scale: 0.65;
      top: 12%;
      left: -5%;
    }
  }

  .stepImgBox3 {
    &::before {
      scale: 0.65;
      bottom: 2%;
      right: 6%;
    }
  }

  .stepImgBox4 {
    &::before {
      scale: 0.65;
      top: 5%;
      left: 5%;
    }
  }

  .stepImgBox5 {
    &::before {
      scale: 0.65;
      top: 12%;
      right: 12%;
    }
  }
}

@media screen and (max-width: 768px) {
  .platformInfo {
    padding: 10px 50px 70px;
  }
}

@media screen and (max-width: 767px) {
  .platformInfo {
    padding: 10px 50px 70px;
  }

  .platformInfoHeader {
    margin-bottom: 0px;
  }

  .container {
    padding-top: 0;
    margin-bottom: 50px;
    grid-template-columns: 1fr;
    row-gap: 10px;
    justify-items: center;
  }

  .stepNumber {
    font-size: 150px;
    line-height: 189px;
  }

  .stepTitle {
    font-size: 30px;
    line-height: 37px;
    max-width: 100%;
  }

  .stepDescription {
    font-size: 20px;
    line-height: 35px;
    max-width: 100%;
  }

  .stepTextBox2,
  .stepTextBox4 {
    padding-top: 0px;
    justify-self: center;
  }

  .stepTextBox1,
  .stepTextBox3 {
    padding-top: 0px;
  }

  .stepTextBox:nth-child(4) {
    grid-row: 3;
  }

  .stepTextBox:nth-child(8) {
    grid-row: 7;
  }

  .stepImgBox1 {
    width: 100%;

    &::before {
      top: 19%;
      right: 18%;
    }
  }

  .stepImgBox2 {
    &::before {
      top: 12%;
      left: 5%;
    }
  }

  .stepImgBox3 {
    &::before {
      bottom: 5%;
      right: 7%;
    }
  }

  .stepImgBox4 {
    &::before {
      top: 8%;
      left: 8%;
    }
  }

  .stepImgBox5 {
    &::before {
      top: 14%;
      right: 14%;
    }
  }

  .button {
    padding: 17px 20px 19px;
    font-size: 18px;
    max-width: 180px;
  }
}

@media screen and (max-width: 700px) {
  .platformInfo {
    padding: 10px 10px 10px;
  }

  .platformInfoHeader {
    margin-bottom: 30px;
  }
}
