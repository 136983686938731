.testimonialsContainer {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1210px;
  margin-inline: auto;
  margin-bottom: 105px;
  position: relative;
}

.testimonials {
  padding: 80px 70px 70px 70px;
  background-image: url("../assets/Testimonials/testimonials-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  border-radius: 70px;
}

.testimonialsItem {
  position: relative;
}

.testimonialsTitle {
  margin: 0;
  max-width: 12ch;
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text);
  align-self: center;
}

.testimonialsSliderContainer {
  max-width: 515px;
  width: 100%;
  min-height: 569px;
  border-radius: 70px;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
}

.quote {
  top: -22px;
  left: 70px;
  position: absolute;
  width: 64px;

  svg {
    transition: fill 0.3s;
  }
}

.testimonialsAuthor {
  margin: 0;
  padding: 4px 20px 5px;
  min-width: 154px;
  font-size: 24px;
  font-weight: 700;
  line-height: 39px;
  color: var(--white);
  text-align: center;
  background-color: #a189ff;
  border-radius: 11px;
  display: inline-block;
  position: absolute;
  top: -22px;
  right: 70px;
  transition: background-color 0.3s;
}

.testimonialsText {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 39px;
  color: #516f9a;
  text-align: left;
}

.testimonialsItemNum {
  font-size: 200px;
  font-weight: 800;
  line-height: 252px;
  color: #e7f5ff;
  position: absolute;
  bottom: -57px;
  right: 13px;
  z-index: -1;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 515px;
}

.carouselContainer {
  max-width: 515px;
  width: 100%;
  margin-inline: auto;
  overflow: hidden;
}

.contentContainer {
  display: flex;
  max-width: 515px;
}

.slide {
  padding: 70px 70px 0;
  flex: 0 0 515px;
  text-align: center;
  min-width: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
}

.slideText {
  display: inline-block;
  margin-top: 37px;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: var(--primary-text);
}

.controlsContainer {
  position: absolute;
  bottom: 70px;
  left: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  span {
    font-size: 36px;
    font-weight: 700;
    line-height: 65px;
    color: #487ffa;
    min-width: 105px;
    text-align: center;
  }
}

.prevBtn,
.nextBtn {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;

  svg {
    fill: #487ffa;
    transition: fill 0.3s;
  }

  img {
    display: block;
  }

  &:hover,
  &:active {
    background-color: #487ffa;

    svg {
      fill: var(--white);
    }
  }
}

.prevBtn {
  left: 5%;
  padding-right: 3px;
}

.nextBtn {
  left: 15%;
  padding-left: 3px;
}

.disabledBtn.prevBtn,
.disabledBtn.nextBtn {
  background-color: #cbcfd9;
  cursor: not-allowed;
}

.disabledBtn svg {
  fill: var(--white);
}

@media screen and (max-width: 1024px) {
  .testimonialsAuthor {
    padding: 7px 20px 8px;
    font-size: 20px;
    line-height: 25px;
    top: -20px;
  }

  .quote {
    top: -20px;
  }

  .testimonialsTitle {
    font-size: 36px;
    line-height: 50px;
  }

  .testimonialsText {
    font-size: 20px;
    line-height: 34px;
  }

  .controlsContainer {
    bottom: 70px;
    left: 90px;
    gap: 10px;

    span {
      font-size: 30px;
      line-height: 50px;
    }
  }

  .prevBtn,
  .nextBtn {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 940px) {
  .testimonials {
    padding: 40px 70px 100px 70px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .testimonialsTitle {
    max-width: 15ch;
    text-align: center;
    margin-bottom: 50px;
  }

  .testimonialsText {
    font-size: 20px;
    line-height: 34px;
  }

  .controlsContainer {
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);

    span {
      font-size: 30px;
      line-height: 50px;
    }
  }

  .prevBtn,
  .nextBtn {
    width: 50px;
    height: 50px;
  }

  .testimonialsSliderContainer {
    min-height: 550px;
  }
}

@media screen and (max-width: 570px) {
  .testimonials {
    padding: 40px 20px 100px 20px;
    border-radius: 50px;
  }

  .testimonialsContainer {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 70px;
  }

  .slide {
    padding: 40px 20px 0;
    flex: 0 0 100%;
  }

  .quote {
    top: -24px;
    left: 20px;

    svg {
      width: 53px;
    }
  }

  .testimonialsAuthor {
    right: 20px;
  }

  .testimonialsSliderContainer {
    border-radius: 50px;
    min-height: 454px;
  }

  .testimonialsItemNum {
    font-size: 150px;
    line-height: 189px;
    bottom: -40px;
    right: 13px;
  }

  .carouselContainer {
    max-width: 333px;
  }

  .contentContainer {
    max-width: 333px;
  }

  .controlsContainer {
    bottom: 30px;
    gap: 10px;

    span {
      font-size: 24px;
      line-height: 30px;
      min-width: auto;
    }
  }

  .prevBtn,
  .nextBtn {
    width: 40px;
    height: 40px;

    svg {
      width: 15px;
    }
  }
}
