p {
  margin: 0;
}

.footer {
  background: #29354e;
  width: 100%;
  padding: 50px 40px 30px;
}

.footerContainer {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin-inline: auto;
  max-width: 1170px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.footerSecondRow {
  padding-top: 30px;
  margin-inline: auto;
  max-width: 1170px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logoContainer {
  grid-column: 1 / 2;
  grid-row: 1 / 1;
  height: 50px;
}

.contacts {
  grid-column: 1 / 2;
}

.col1 {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  justify-self: end;
}

.col2 {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  justify-self: end;
}

.col3 {
  grid-column: 4 / 5;
  grid-row: 1 / 3;
  justify-self: end;
}

.logoContainer {
  margin-bottom: 40px;

  img {
    max-width: 185px;
    width: 100%;
    display: block;
  }
}

.paragraphBlock {
  margin-bottom: 30px;
}

.textParagraph {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  color: var(--white);
}

.email {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 39px;
  color: var(--white);
}

.text {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 39px;
  color: var(--white);
}

.socials {
  display: flex;
  gap: 10px;
  margin-bottom: 28px;

  a {
    display: inline-block;
    line-height: 0px;
  }

  svg {
    fill: var(--white);
  }
}

.link {
  padding-top: 12px;
  padding-bottom: 13px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 23px;
  color: var(--white);
  max-width: 20ch;
  cursor: pointer;
}

.copyrightBlock {
  span {
    font-size: 18px;
    font-weight: 500;
    line-height: 39px;
    color: var(--white);
  }
}

.termsContainer,
.privacyContainer {
  display: flex;
  justify-content: flex-end;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 39px;
    color: var(--white);
    cursor: pointer;
  }
}

@media screen and (max-width: 950px) {
  .footer {
    padding: 40px 10px 30px;
  }

  .footerContainer {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .footerSecondRow {
    flex-direction: column;
    align-items: center;
    gap: 13px;
  }

  .logoContainer {
    margin-bottom: 25px;
    grid-row: 1;
    grid-column: span 4;
    justify-self: center;
  }

  .col1 {
    grid-row: 2;
    grid-column: span 4;
    justify-self: center;
  }

  .col2 {
    grid-row: 3;
    grid-column: span 4;
    justify-self: center;
  }

  .col3 {
    margin-bottom: 30px;
    grid-row: 4;
    grid-column: span 4;
    justify-self: center;
  }

  .contacts {
    grid-row: 5;
    grid-column: span 4;
    justify-self: center;
  }

  .link {
    margin-bottom: 5px;
    text-align: center;
    max-width: 100%;
  }

  .contacts {
    text-align: center;
  }

  .socials {
    justify-content: center;
  }
}
