@import "/node_modules/primeflex/primeflex.css";

.default-button {
  background: var(--main-blue);
  color: var(--white);
  border: none;
  cursor: pointer;

  &:disabled {
    background: var(--second-text);
  }

  &:hover {
    background: var(--main-blue-hover);
  }
}
