.images.carousel {
  position: relative;
  max-width: 810px;
  width: 100%;
  margin-inline: auto;
}

.carouselContainer {
  margin-inline: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.textImages {
  max-width: 1170px;
  margin-inline: auto;
}

.textImages .carouselContainer {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.contentContainer {
  display: flex;
  margin-bottom: 53px;
  gap: 120px;
}

.textImages .contentContainer {
  margin-bottom: 20px;
}

.textImages .infoSlide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide {
  flex: 0 0 100%;
  text-align: center;
  min-width: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
}

.slideImg {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.slideText {
  display: inline-block;
  margin-top: 37px;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: var(--primary-text);
}

.notSnapped {
  opacity: 0.5;
}

.prevBtn,
.nextBtn {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: var(--white);
  box-shadow: 0 0 14px rgba(70, 80, 111, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 186px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;

  svg {
    fill: #487ffa;
    transition: fill 0.3s;
  }

  img {
    display: block;
  }

  &:hover,
  &:active {
    background-color: #487ffa;

    svg {
      fill: var(--white);
    }
  }
}

.prevBtn {
  right: 100.5%;
  padding-right: 3px;
}

.nextBtn {
  left: 100.5%;
  padding-left: 3px;
}

.disabledBtn.prevBtn,
.disabledBtn.nextBtn {
  background-color: #cbcfd9;
  cursor: not-allowed;
}

.disabledBtn svg {
  fill: var(--white);
}

.carouselIndicator {
  display: flex;
  justify-content: center;
  gap: 14px;
  max-width: 320px;
  margin-inline: auto;
}

.carouselIndicatorItem {
  padding: 0;
  width: 10px;
  height: 10px;
  background-color: #e7f5ff;
  border: 1px solid var(--main-blue);
  border-radius: 50%;
  cursor: pointer;
}

.carouselIndicator .carouselIndicatorActive {
  background-color: var(--main-blue);
}

.mobile {
  position: relative;
  width: 351px;

  .carouselContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 45px;
    overflow: hidden;
  }

  .carouselContentContainer {
    display: flex;
    margin-bottom: 25px;
  }

  .slide {
    flex: 0 0 351px;
    min-width: 0;
    display: flex;
    justify-content: center;
    transition: opacity 0.5s ease-in-out;
  }

  .prevBtn,
  .nextBtn {
    width: 40px;
    height: 40px;
    top: 186px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1000;

    svg {
      width: 15px;
    }

    &:hover,
    &:active {
      background-color: #487ffa;

      svg {
        fill: var(--white);
      }
    }
  }

  .prevBtn {
    position: absolute;
    left: -20px;
    top: 47.5%;
  }

  .nextBtn {
    position: absolute;
    left: 330px;
    top: 47.5%;
  }
}

.textImages {
  .slide {
    max-height: max-content;
  }

  .prevBtn,
  .nextBtn {
    background-color: #e7f5ff;
    top: 49.5%;

    &:hover,
    &:active {
      background-color: #487ffa;

      svg {
        fill: var(--white);
      }
    }
  }

  .prevBtn {
    left: 0px;
  }

  .nextBtn {
    left: calc(100% - 70px);
  }

  .disabledBtn.prevBtn,
  .disabledBtn.nextBtn {
    background-color: #cbcfd9;
    cursor: not-allowed;
  }

  .disabledBtn svg {
    fill: var(--white);
  }
}

.slideNumber,
.slideTitle {
  margin: 0;
}

.slideNumber {
  font-size: 150px;
  font-weight: 800;
  line-height: 180px;
  text-align: left;
  color: #caaaff;
}

.slideNumber2 {
  color: #aadb49;
}

.slideNumber3 {
  color: #76d7fb;
}

.slideNumber4 {
  color: #ff83bb;
}

.slideNumber5 {
  color: #f9df6f;
}

.slideTextBox {
  flex: 1;
}

.slideTitle {
  max-width: 16ch;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  color: var(--primary-text);
  margin-bottom: 24px;
}

.stepTextBox5 .slideTitle {
  margin-bottom: 28px;
}

.slideDescription {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  color: #516f9a;
  text-align: left;
  max-width: 63%;
}

.slideImgBox {
  position: relative;
  align-self: center;
  display: flex;
  padding-top: 10px;
  flex: 1;

  &::before {
    position: absolute;
  }
}

.slideImgBox1 {
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../assets/PlatformInfo/imageBg1.svg");
    width: 155px;
    height: 123px;
    top: 14%;
    right: 10%;
  }
}

.slideImgBox2 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg2.svg");
    width: 383px;
    height: 145px;
    top: 16%;
    right: 22%;
  }
}

.slideImgBox3 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg3.svg");
    width: 150px;
    height: 132px;
    bottom: 4%;
    right: 11%;
  }
}

.slideImgBox4 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg4.svg");
    width: 256px;
    height: 185px;
    top: 9%;
    left: 10%;
  }
}

.slideImgBox5 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg5.svg");
    width: 171px;
    height: 132px;
    top: 15%;
    right: 16%;
  }
}

@media screen and (max-width: 1320px) {
  .slideDescription {
    max-width: 80%;
  }
}

@media screen and (max-width: 1200px) {
  .images {
    .contentContainer {
      gap: 80px;
    }

    .prevBtn {
      right: calc(100% - 2%);
    }

    .nextBtn {
      left: calc(100% - 2%);
    }
  }
}

@media screen and (max-width: 1024px) {
  .images.carousel {
    max-width: 650px;
  }

  .images {
    .contentContainer {
      margin-bottom: 23px;
    }

    .slideText {
      margin-top: 30px;
      font-size: 28px;
      line-height: 35px;
    }

    .prevBtn,
    .nextBtn {
      width: 50px;
      height: 50px;
      top: 35%;
    }

    .prevBtn {
      right: calc(100% - 1%);
    }

    .nextBtn {
      left: calc(100% - 1%);
    }
  }

  .slideNumber {
    font-size: 120px;
    line-height: 150px;
  }

  .slideTitle {
    font-size: 32px;
    line-height: 38px;
  }

  .slideDescription {
    font-size: 18px;
    line-height: 32px;
  }

  .slideImgBox1 {
    width: 100%;

    &::before {
      background-size: cover;
      width: 104px;
      height: 82px;
      top: 17%;
      right: 16%;
    }
  }

  .slideImgBox2 {
    &::before {
      scale: 0.65;
      top: 12%;
      left: -5%;
    }
  }

  .slideImgBox3 {
    &::before {
      scale: 0.65;
      bottom: 2%;
      right: 6%;
    }
  }

  .slideImgBox4 {
    &::before {
      scale: 0.65;
      top: 5%;
      left: 5%;
    }
  }

  .slideImgBox5 {
    &::before {
      scale: 0.65;
      top: 12%;
      right: 12%;
    }
  }
}

@media screen and (max-width: 820px) {
  .slideDescription {
    max-width: 85%;
  }
}

@media screen and (max-width: 768px) {
  .textImages {
    .prevBtn,
    .nextBtn {
      width: 50px;
      height: 50px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .nextBtn {
      left: calc(100% - 50px);
    }
  }
}

@media screen and (max-width: 767px) {
  .images {
    .carouselContainer {
      padding-left: 10px;
      padding-right: 10px;
    }

    .prevBtn,
    .nextBtn {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .prevBtn {
      right: calc(100% - 5%);
    }

    .nextBtn {
      left: calc(100% - 5%);
    }
  }
}

@media screen and (max-width: 700px) {
  .carouselIndicator {
    display: none;
  }

  .textImages .infoSlide {
    flex-direction: column;
  }

  .textImages .slideNumber {
    text-align: center;
    font-size: 5rem;
    line-height: 5rem;
    margin-bottom: 12px;
  }

  .textImages .slideTitle {
    text-align: center;
    max-width: 100%;
    font-size: 1.875rem;
    margin-bottom: 55px;
    min-height: 76px;
  }

  .textImages .slideDescription {
    font-size: 1.25rem;
    line-height: 35px;
    max-width: 100%;
    margin-bottom: 25px;
  }

  .textImages {
    .btnContainer {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 10px;
      top: 355px;
      left: 50%;
      transform: translateX(-50%);
    }

    .prevBtn,
    .nextBtn {
      position: relative;
      width: 40px;
      height: 40px;
      left: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .slideImgBox {
      width: 100%;
      overflow: hidden;
      max-height: 450px;
      border-radius: 20px;
      padding-top: 0;
    }
  }

  .slideImgBox1,
  .slideImgBox2,
  .slideImgBox3,
  .slideImgBox4,
  .slideImgBox5 {
    &::before {
      display: none;
    }
  }
}

@media screen and (max-width: 670px) {
  .images {
    .prevBtn,
    .nextBtn {
      width: 40px;
      height: 40px;
      top: calc(50% - 70px);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .prevBtn {
      right: calc(100% - 50px);
    }

    .nextBtn {
      left: calc(100% - 50px);
    }
  }
}

@media screen and (max-width: 440px) {
  .textImages {
    .slideImgBox {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 200px;
      padding-top: 100px;
    }
  }
}

@media screen and (max-width: 395px) {
  .mobile {
    .prevBtn {
      left: -15px;
    }

    .nextBtn {
      left: 325px;
    }
  }
}

@media screen and (max-width: 375px) {
  .mobile {
    .prevBtn {
      // top: 40%;
      left: -5px;
    }

    .nextBtn {
      left: 315px;
    }
  }
}
