.feedback {
  background-image: url("../assets/Feedback/feedback-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e7f5ff;
  position: relative;
  padding-bottom: 81px;
  padding-top: 230px;
}

.feedbackBg {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: -180px;

  img {
    margin-left: -60px;
    display: block;
    width: 100%;
  }
}

.feedbackContainer {
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1210px;
  width: 100%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex: 1;
}

.formContainer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1023px) {
  .feedbackContainer {
    padding-right: 10px;
    padding-left: 10px;
    flex-direction: column;
    gap: 50px;
  }

  .feedbackBg {
    order: 1;
    width: 75%;
    margin-left: 0px;

    img {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .feedbackBg {
    width: 100%;
    margin-left: -50px;
  }
}
