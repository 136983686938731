.topBar {
  top: 0;
  background-color: #eff8ff;
  z-index: 10000;
  position: sticky;
  border-bottom: 1px solid #e7f4ff;
}

.top {
  box-sizing: border-box;
  width: 100%;
  max-width: 1210px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 15px 20px;
}

.buttonsGroup {
  display: flex;
  align-items: center;
  gap: 20px;
}

.headerLogo {
  display: block;
}

.buttonsGroupMobile {
  display: none;
}

.button {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  border-radius: 13px;
  padding: 13px 24px;
  transition: background-color 0.3s;
}

@media screen and (max-width: 1200px) {
  .top {
    padding: 15px 30px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .top {
    max-width: 1100px;
  }
}

@media screen and (max-width: 768px) {
  .topBar {
    padding: 0 0;
  }

  .headerLogo {
    width: 146px;
    height: auto;
  }

  .button {
    font-size: 16px;
    line-height: 1.5;
    border-radius: 11px;
    padding: 8px 15px;
  }
}

@media screen and (max-width: 700px) {
  .headerLogo {
    width: 143px;
    height: auto;
  }

  .buttonsGroupMobile {
    background: var(--main-blue);
    border-radius: 5px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
      color: var(--main-blue-hover);
    }
  }
}

@media screen and (max-width: 480px) {
  .top {
    padding: 10px;
  }
}
